import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const getClasses = makeStyles(
    ({
        palette: {
            primary: { main: primaryMain },
            secondary: { main: secondaryMain },
        },
    }: Theme) => ({
        list: {
            listStyle: "none",
            padding: 0,
        },
        listItem: {
            paddingBottom: 15,
        },
        menu: {
            paddingLeft: 29,
            paddingRight: 29,
        },
        title: {
            fontSize: 18,
            paddingBottom: 18,
            paddingTop: 45,
            fontWeight: "bold",
        },
        subTitle: {
            fontSize: 13,
            color: "black",
            textDecoration: "none",
        },
        buttonJustDonate: {
            backgroundColor: "transparent",
            border: `2px solid ${secondaryMain}`,
            height: 44,
            marginBottom: 30,
            color: secondaryMain,
            borderRadius: 5,
            fontWeight: 700,
            "&:hover": {
                backgroundColor: "transparent !important",
            },
        },
    })
);
