module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-175594466-1","head":false,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"defer":true,"anonymize":false,"respectDNT":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WPM6KBF","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"palette":{"primary":{"main":"#BA3D3B"}}}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WPM6KBF","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
